import { z } from 'zod';
import { MediaRepository } from './MediaRepository';
import { COLOR_TYPE } from '~/constants/ColorType';
import { wpRestApiFetcher } from '~/libs/fetcher';
import { type Company } from '~/types/Company';

// Define allowed domains
const allowed_domains = [
  'st.relo.jp', // dev
  's.relo.jp', // production
];

// Utility function to validate referrer domain
const isAllowedDomain = (referrer: string | null): boolean => {
  if (!referrer) return false;

  try {
    const referrerDomain = new URL(referrer).hostname;
    return allowed_domains.some((domain) => referrerDomain.endsWith(domain));
  } catch (error) {
    return false;
  }
};

/**
 * カラーのスキーマ
 * これだけ固定値で決まってるので、Zodでまじめにやる
 */
const colorTypeSchema = z.nativeEnum(COLOR_TYPE);

export const CompanyRepository = () => {
  const getCompany = async (companySlug: string): Promise<Company> => {
    // Get referrer from document (browser-side) or request headers (server-side)
    const referrer = typeof document !== 'undefined' ? document.referrer : '';

    // Validate if the referrer domain is allowed & test mode for unit test
    if (companySlug != 'test' && !isAllowedDomain(referrer)) {
      throw createError({
        status: 404,
        message: 'notAllowedDomain',
      });
    }

    const { data } = await wpRestApiFetcher<any>(
      `/company?slug=${companySlug}`,
      {
        method: 'GET',
      },
    );

    if (!data || (data.length && data.length === 0))
      throw createError({
        status: 404,
        message: '存在しない企業スラッグです。',
      });

    // colorTypeのチェック
    // 不正だったら、returnのところで、デフォルトカラーのblueを返す
    const isColorTypeValid = colorTypeSchema.safeParse(
      data[0].acf.company_color,
    );

    // WP側で必須にしてるので、falsyチェックしない
    const companyTopImage = data[0].acf.company_top_image;
    const { getMedia } = MediaRepository();
    const [logo, term] = await Promise.all([
      getMedia(companyTopImage),
      data[0].acf.company_term ? getMedia(data[0].acf.company_term) : null,
    ]);

    return {
      id: data[0].id,
      slug: data[0].slug,
      name: data[0].name,
      logoUrl: logo.url,
      colorType: isColorTypeValid.success
        ? data[0].acf.company_color
        : COLOR_TYPE.Blue,
      tel: data[0].acf.company_tel,
      termUrl: term?.url,
    };
  };

  return {
    getCompany,
  };
};
